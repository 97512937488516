import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';

const pkgName = process.env.REACT_APP_PACKAGE_NAME;

const EVENTS = {
  append: `${pkgName}:StickyAlerts:append`,
};

export default function StickyAlertsWrapper() {
  const wrapperRef = useRef(null);
  const [alerts, setAlerts] = useState([]);

  // // Useful for testing:
  // useEffect(() => {
  //   setTimeout(() => {
  //     document.dispatchEvent(
  //       new CustomEvent(EVENTS.append, {
  //         detail: {
  //           key: 'outbid',
  //           id: 'outbid',
  //           sale: { id: 1, product: { name: 'example.com' } },
  //         },
  //       }),
  //     );
  //   }, 1000);
  //   setTimeout(() => {
  //     document.dispatchEvent(
  //       new CustomEvent(EVENTS.append, {
  //         detail: {
  //           key: 'outbid',
  //           id: 'outbid',
  //           sale: { id: 3, product: { name: 'example2.com' } },
  //         },
  //       }),
  //     );
  //   }, 3000);
  //   setTimeout(() => {
  //     document.dispatchEvent(
  //       new CustomEvent(EVENTS.append, {
  //         detail: {
  //           key: 'outbid',
  //           id: 'outbid',
  //           sale: { id: 2, product: { name: 'example3.com' } },
  //         },
  //       }),
  //     );
  //   }, 10000);
  // }, []);

  useEffect(() => {
    const listener = event => {
      setAlerts(alerts => {
        const newAlert = { ...event.detail };
        if (
          // Give alerts a unique id to prevent showing two notifications for the same event.
          // Otherwise falls back to key.
          alerts.find(
            alert => alert?.id === newAlert?.id && alert.key === newAlert.key,
          )
        )
          return alerts;
        return [...alerts, newAlert];
      });
      if (process.env.NODE_ENV === 'development') {
        console.log(`${EVENTS.append} listener fired`);
        console.table(event.detail);
      }
    };
    document.addEventListener(EVENTS.append, listener);
    return () => document.removeEventListener(EVENTS.append, listener);
  }, []);

  const onDismiss = dismissedAlert => {
    setAlerts(alerts => {
      return alerts.filter(alert => alert.id !== dismissedAlert.id);
    });
  };

  return (
    <div className="gb-alerts-sticky">
      <div className="gb-alerts-sticky__wrapper" ref={wrapperRef}>
        {alerts.map(alert =>
          alert.key === 'outbid' ? (
            <StickyAlert
              className="gb-alert--info"
              key={alert.id || alert.key}
              onDismiss={() => onDismiss(alert)}>
              <div className="gb-alert__icon">
                <i className="gb-icon gb-icon-morpheus-info-full"></i>
              </div>
              <div className="gb-alert__content">
                <h2 className="gb-alert__title">
                  Someone outbid you on {alert.sale.product.name}
                </h2>
              </div>
              <div className="gb-alert__actions">
                <Link
                  className="gb-btn gb-btn--sm"
                  to={`/${alert.sale.product.name}/`}>
                  Bid Again
                </Link>
              </div>
            </StickyAlert>
          ) : alert.key === 'unpaidInvoices' ? (
            <StickyAlert
              className="gb-alert--info"
              key={alert.id || alert.key}
              onDismiss={() => onDismiss(alert)}>
              <div className="gb-alert__icon">
                <i className="gb-icon gb-icon-morpheus-info-full"></i>
              </div>
              <div className="gb-alert__content">
                <h2 className="gb-alert__title">
                  Your won domains are ready for purchase
                </h2>
              </div>
              <div className="gb-alert__actions">
                <Link className="gb-btn gb-btn--sm" to={`/dashboard/invoices/`}>
                  Checkout
                </Link>
              </div>
            </StickyAlert>
          ) : null,
        )}
      </div>
    </div>
  );
}

function StickyAlert({ children, className, onDismiss }) {
  const ref = useRef(null);
  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const clickableElements = ref.current?.querySelectorAll('a, button');
    const listener = () => setDismissed(true);
    clickableElements?.forEach(node =>
      node.addEventListener('click', listener),
    );
    return () => {
      clickableElements?.forEach(node =>
        node.removeEventListener('click', listener),
      );
    };
  }, []);

  return (
    <Transition
      afterLeave={onDismiss}
      appear
      show={!dismissed}
      enter="tw-transition-all motion-reduce:tw-transition-none tw-ease tw-duration-500"
      enterFrom="tw--translate-y-full tw-opacity-0"
      enterTo="tw-translate-y-0 tw-opacity-100"
      leave="tw-transition-opacity tw-ease tw-duration-500"
      leaveFrom="tw-opacity-100"
      leaveTo="tw-opacity-0">
      <div className={cx('gb-alert sm:tw-w-96', className)} ref={ref}>
        <button
          className="gb-alert__close"
          onClick={() => setDismissed(true)}
          type="button">
          Close
        </button>
        <div className="gb-alert__body">{children}</div>
      </div>
    </Transition>
  );
}

StickyAlertsWrapper.events = EVENTS;
